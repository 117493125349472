import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { AuthService } from './services/base/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'konceitus-angular';

  constructor(private router: Router, private auth: AuthService) { }

  async ngOnInit(): Promise<void> {
    let token = sessionStorage.token;
    if (token) {
      let response = await this.auth.verifyToken(token);
      if (response.access) {
        this.router.navigate([location.pathname]);
        return;
      };
      this.router.navigate(['/']);
    }
    sessionStorage.clear();
    this.router.navigate(['auth']);
  }
}
