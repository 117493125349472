import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/base/auth/auth.service';
import { MessagesService } from 'src/app/services/base/messages/messages.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  id: number = 0;
  time: string;
  todayDate: any;
  nome: string;
  constructor(
    private auth: AuthService,
    private messages: MessagesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setDate();
    this.nome = sessionStorage.nome;
    this.countDate();
  }

  setDate() {
    let today = new Date();
    this.todayDate = new Intl.DateTimeFormat('pt-EU', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    }).format(today);

    let aux = new Date();
    this.time = aux.toLocaleString().split(', ')[1].substr(0, 5);
  }

  countDate() {
    setInterval(() => {
      let aux = new Date();
      this.time = aux.toLocaleString().split(', ')[1].substr(0, 5);
    }, 60000);
    setInterval(() => {
      let timeAux: any = new Date(sessionStorage.time);
      let now: any = new Date();
      let diff = now - timeAux;
      let diffMinutes = diff / 60000;

      if (diffMinutes >= 5) {
        this.auth.logout();
        this.messages.swalSuccess('Sessão Expirada');
        this.router.navigate(['auth']);
      }
    }, 1000);
  }

  async terminarSessao() {
    let response = await this.messages.terminarSessao();
    if (response.isConfirmed) {
      this.auth.logout();
      this.messages.swalSuccess('Obrigado');
      this.router.navigate(['auth']);
      return;
    }
  }
}
