<div class="container ">
    <div class="row align-vertical">
        <div class="col-12 text-center">
            <figure>
                <img src="assets/imgs/logo-tela-login.png " alt="Logo" width="322" class="img-fluid d-block mx-auto ">
            </figure>
        </div>
        <div class="col-12 text-center">
            <h5>Lamentamos mas houve um erro. Volte por favor à página inicial.</h5>
            <a routerLink="/encomendas"> Voltar a Página inicial</a>
        </div>
    </div>
</div>