<header class="header">
    <div class="row">
        <div class="col-md-4 btn logo" routerLink="/">
            <svg-icon src="/assets/imgs/logo_h.svg" [svgStyle]="{ 'height.px':54 }"></svg-icon>
        </div>
        <div class="col-md-8">
            <div class="row text-right">
                <div class="col-md-4">
                    <span class="utilizador">
                        <small>utilizador</small>
                        <p class="nome">{{nome}}</p>
                    </span>
                </div>
                <div class="col-md-4">
                    <span class="utilizador">
                        <small>{{todayDate}}</small>
                        <p class="hora">{{time}}</p>
                    </span>
                </div>
                <div class="col-md-4">
                    <button class="btn btn-dark btn-block" (click)="terminarSessao()">Terminar Sessão</button>
                </div>
            </div>

        </div>
    </div>
</header>