import { environment } from '../../../../environments/environment';
import axios from 'axios';

export class ApiService {
  private request = axios.create({
    baseURL: environment.urlAPI,
    timeout: 3000,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });

  

  constructor() {
    console.log("Users :",this.request);
    this.request.interceptors.request.use( (config) =>{
      config.headers.Authorization = `Bearer ${sessionStorage.token}`;
      return config;
    });
  }

  async readId(url: String, id: Number) {
    try {
      let response = await this.request.get(`${url}/${id}`);
      return await (response.data.data);
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response; 
      return {
        message:"error",
        data: errorObject.data.data,
      }
    }
  }

  async readIdTab(url: String, id: Number, tab: Number) {
    try {
      let response = await this.request.get(`${url}/${id}/${tab}`);
      return await (response.data.data);
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response; 
      return {
        message:"error",
        data: errorObject.data.data,
      }
    }
  }

  async readURL(url: string, find: string) {
    let auxUrl = `${url}/${find}`;
    try {
      let response = await this.request.get(auxUrl);
      return (response.data);
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response; 
      return {
        message:"error",
        data: errorObject.data.data,
      }
    }
  }

  async read(url: string, params: string = "") {
    try {
      let response = await this.request.get(url, {
        params: params
      });
      return await (response.data.data);
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response; 
      return {
        message:"error",
        data: errorObject.data.data,
      }
    }
  }
  async readNoBar(url: string, params: String = "") {
    try {
      let response = await this.request.get(url, {
        params: params
      });
      return await (response.data.data);
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response; 
      return {
        message:"error",
        data: errorObject.data.data,
      }
    }
  }

  async create(url: string, data: any) {
    try {
      let response = await this.request.post(url, data);
      return await (response.data);
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response; 
      return {
        message:"error",
        data: errorObject.data.data,
      }
    }
  }

  async update(url: string, data: any) {
    try {
      let response = await this.request.post(url, {
        body: JSON.stringify(data)
      });
      return await (response.data);
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response; 
      return {
        message:"error",
        data: errorObject.data.data,
      }
    }
  }

  async post(url: string, data: any = []) {
    console.log("urls :",this.request)
    try {
      let response = await this.request.post(url, data);
      return await (response.data);
    } catch (error) {
      console.log('error : ', error)
      const { response } = error;
      const { request, ...errorObject } = response; 
      return {
        message:"error",
        data: errorObject.data.data,
      }
    }
  }
  async delete(url: string) {
    try {
      let response = await this.request.delete(url);
      return await (response.data);
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response; 
      return {
        message:"error",
        data: errorObject.data.data,
      }
    }
  }
}
