import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  constructor() {}

  MSG_SUCCESS: string = 'Realizado com sucesso';
  MSG_ERROR: string = 'Não realizado';
  MSG_RETRY: string = 'Tente novamente mais tarde';
  TIMER: number = 1500;

  swalSuccess(msg: string = this.MSG_SUCCESS, title: string = 'Sucesso') {
    Swal.fire({
      icon: 'success',
      title: title,
      text: msg,
      timer: this.TIMER,
    });
  }
  swalError(msg: string = this.MSG_ERROR, title: string = 'Erro') {
    Swal.fire({
      icon: 'error',
      title: title,
      text: msg,
      timer: this.TIMER,
    });
  }
  toastSuccess(msg: string = this.MSG_SUCCESS) {
    Swal.fire({
      icon: 'success',
      title: msg,
      toast: true,
      showConfirmButton: false,
      position: 'top-end',
      timer: this.TIMER,
    });
  }
  toastError(msg: string = this.MSG_ERROR) {
    Swal.fire({
      icon: 'error',
      title: msg,
      toast: true,
      showConfirmButton: false,
      position: 'top-end',
      timer: this.TIMER,
    });
  }
  toastLoading() {
    Swal.fire({
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
      position: 'top-end',
    });
  }
  swalLoading() {
    Swal.fire({
      title: 'A carregar!',
      html: 'Por favor, aguarde',
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  swalClose() {
    Swal.close();
  }

  terminarSessao() {
    return Swal.fire({
      icon: 'info',
      title: 'Deseja terminar sessão?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`,
    });
  }
}
