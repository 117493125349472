import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private api: ApiService,private httpClient: HttpClient) { }
  info: any;

  async login(user: any) {
    let response = await this.api.post("/auth/login", user);
    if (response.message == "success") {
      sessionStorage.token = response.data.token;
      sessionStorage.nome = response.data.nome;
      sessionStorage.t = response.data.nome;
      sessionStorage.time = new Date().toISOString();
      return { access: true, info: response.data };
    }
    return { access: false, messsage: response.data[0].message };
  }
  async verifyToken(token: string) {
    let response = await this.api.post("auth/token",token);
    if (!response.e && response.data != undefined) {
      sessionStorage.nome = response.data.nome;
      sessionStorage.token = token;
      sessionStorage.time = new Date().toISOString();
      return { access: true, info: response.data };
    }
    return { access: false, messsage: response.e };
  }

  logout(){
    sessionStorage.clear();
  }
}
