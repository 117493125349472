export const environment = {
  production: true,
  // urlAPI: "http://localhost:8080/",
  urlAPI: "https://kplataforma.konceitus.com/api/public/index.php",
  // urlAPI: "https://api.konceitus.com",
  urlSite: "http://kplataforma.konceitus.com",
  firebase: {
    apiKey: "AIzaSyC8rk4dodEXt2gTX3rhxu01OoNfAvd0niI",
    authDomain: "konceitus-netgocio.firebaseapp.com",
    databaseURL: "https://konceitus-netgocio-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "konceitus-netgocio",
    storageBucket: "konceitus-netgocio.appspot.com",
    messagingSenderId: "4045279784",
    appId: "1:4045279784:web:fdadf3ef26530599075b1d",
    measurementId: "G-5P11HE182W"
  },
  urlBaseFirebase: "encomendas"
};
